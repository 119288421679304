import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "generic-types"
    }}>{`Generic Types`}</h1>
    <hr></hr>
    <p>{`Sometimes you can have different types for the same logic. The easiest example of this case is `}<inlineCode parentName="p">{`identity`}</inlineCode>{` function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function identity(value) {
  return value;
}
`}</code></pre>
    <p>{`If you try to annotate only types with which you currently use this function you will have multiple declaration of the same function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function identityString(value: string): string {
  return value;
}

function identityNumber(value: number): number {
  return value;
}

function identityBoolean(value: boolean): boolean {
  return value;
}

// Type of num is "number"
let num = identityNumber(2);

// Type of str is "string"
let str = identityString("2");

// Type of bool is "boolean"
let bool = identityBoolean(false);
`}</code></pre>
    <p>{`As you can see, you need to redefine the `}<inlineCode parentName="p">{`identity`}</inlineCode>{` function only for type safety. These functions do not add new business value to your code. You can try to solve this problem with `}<a parentName="p" {...{
        "href": "/docs/unknown-type"
      }}>{`Unknown Type`}</a>{`, but you will lose the type of the return value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function identity(value: unknown): unknown {
  return value;
}

// Type of num is "unknown"
let num = identity(2);

// Type of str is "unknown"
let str = identity("2");

// Type of bool is "unknown"
let bool = identity(false);
`}</code></pre>
    <p>{`And then Generic Types appear.`}</p>
    <p>{`Generic Types are the way to create something like a "type function". You can define "type arguments" which can be applied to this "type function" and a new type will be returned.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// "T" is type variable
function identity<T>(value: T): T {
  return value;
}

// T will be replaced by "number".
// Type of num is "number"
let num = identity<number>(2);

// T will be replaced by "string".
// Type of str is "string"
let str = identity<string>("2");

// T will be replaced by "boolean".
// Type of bool is "boolean"
let bool = identity<boolean>(false);
`}</code></pre>
    <p>{`And also, you can drop this "type application", because Hegel will infer which type you want to use.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// "T" is type variable
function identity<T>(value: T): T {
  return value;
}

// T will be replaced by type of 2.
// Type of num is "number"
let num = identity(2);

// T will be replaced by type of "2"
// Type of str is "string"
let str = identity("2");

// T will be replaced by type of false
// Type of bool is "boolean"
let bool = identity(false);
`}</code></pre>
    <p>{`Generics can be used within functions, function types, classes and type aliases.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Response<Body> = { status: 200; body: Body };

function respondWith<Body>(body: Body): Response<Body> {
  return { status: 200, body };
}

// Type of response1 is "Response<{ message: 'Good response' }>"
// is the same as "{ status: 200, body: { message: "Good response " } }"
const response1 = respondWith({ message: "Good response" });

// Type of response2 is "Response<[1, 2, 3]>"
// is the same as "{ status: 200, body: [1, 2, 3] }"
const response2 = respondWith([1, 2, 3]);

// Type of bodyOfResponse2 is [1, 2, 3]"
const bodyOfResponse2 = response2.body;
`}</code></pre>
    <h2 {...{
      "id": "generic-syntax"
    }}>{`Generic Syntax`}</h2>
    <p>{`As was mentioned before, generics can be used within functions, function types, classes and type aliases.
So, there are different syntaxes in different places.`}</p>
    <h3 {...{
      "id": "functions"
    }}>{`Functions`}</h3>
    <p>{`To define generic parameters for a function you need to add a sequence of needed type variables wrapped in `}<inlineCode parentName="p">{`<`}</inlineCode>{` `}<inlineCode parentName="p">{`>`}</inlineCode>{` (angle brackets) separated by `}<inlineCode parentName="p">{`,`}</inlineCode>{` (comma) before arguments list`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Function Declaration Generic Syntax
function getResponseBodyAndStatus<Status, Body>(response: {
  status: Status;
  body: Body;
}): [Status, Body] {
  return [response.status, response.body];
}

// Function Expression Generic Syntax
const getResponseBodyAndStatus = function <Status, Body>(response: {
  status: Status;
  body: Body;
}): [Status, Body] {
  return [response.status, response.body];
};

// Arrow Function Expression Generic Syntax
const getResponseBodyAndStatus = <Status, Body>(response: {
  status: Status;
  body: Body;
}): [Status, Body] => [response.status, response.body];

const obj = {
  // Method Generic Syntax
  getResponseBodyAndStatus<Status, Body>(response: {
    status: Status;
    body: Body;
  }): [Status, Body] {
    return [response.status, response.body];
  },
};
`}</code></pre>
    <h3 {...{
      "id": "function-type"
    }}>{`Function Type`}</h3>
    <p>{`Function type has the same syntax as for "Arrow Function Expression" with generic:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Function Type Generic Syntax
const getResponseBodyAndStatus: <Status, Body>({
  status: Status,
  body: Body,
}) => [Status, Body] = function (response) {
  return [response.status, response.body];
};
`}</code></pre>
    <h3 {...{
      "id": "classes"
    }}>{`Classes`}</h3>
    <p>{`To define generic parameters for class you need to add sequence of needed type variables wrapped in `}<inlineCode parentName="p">{`<`}</inlineCode>{` `}<inlineCode parentName="p">{`>`}</inlineCode>{` (angle brackets) separated by `}<inlineCode parentName="p">{`,`}</inlineCode>{` (comma) after class identifier`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Container<T> {
  value: T;

  constructor(value: T) {
    this.value = value;
  }
}

let value = 2;

// Explicit type application
// container1 type is Container<number>
const container1 = new Container<number>(value);

// Implicit type application
// container2 type is Container<number>
const container2 = new Container(value);
`}</code></pre>
    <h3 {...{
      "id": "type-alias"
    }}>{`Type Alias`}</h3>
    <p>{`To define generic parameters for type aliases you need to add a sequence of needed type variables wrapped in `}<inlineCode parentName="p">{`<`}</inlineCode>{` `}<inlineCode parentName="p">{`>`}</inlineCode>{` (angle brackets) separated by `}<inlineCode parentName="p">{`,`}</inlineCode>{` (comma) after type alias identifier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Container<T> = { value: T };

const container: Container<number> = { value: 2 };
`}</code></pre>
    <h2 {...{
      "id": "type-checking"
    }}>{`Type Checking`}</h2>
    <p>{`First of all, if you defined some type as generic you can't use this type without type application.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Container<T> {
  value: T;

  constructor(value: T) {
    this.value = value;
  }
}

const container1: Container<number> = new Container(2); // 👌!

// Error: Generic type "Container<T>" should be used with type parameters!
const container2: Container = new Container(2);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Container<T> = { value: T };

const container1: Container<number> = { value: 2 }; // 👌!

// Error: Generic type "Container<T>" should be used with type parameters!
const container2: Container = { value: 4 };
`}</code></pre>
    <p>{`As you may understand, none value will be valid for "type variable". Only arguments which annotated as "type variable" will be a valid value for this type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function getResponseBodyAndStatus<Status, Body>(response: {
  status: Status;
  body: Body;
}): [Status, Body] {
  // Error: Type "[Status, 'Custom Body']" is incompatible with type "[Status, Body]"
  return [response.status, "Custom Body"];
}
`}</code></pre>
    <p>{`Also, the same as `}<a parentName="p" {...{
        "href": "/docs/unknown-type"
      }}>{`Unknown Type`}</a>{` you can't get properties from "type variable", because you can be replaced by "undefined", "null" or object which doesn't contain this property.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function length<T>(somethingWithLength: T) {
  // Error: Property "length" does not exist in "T"
  return somethingWithLength.length;
}
`}</code></pre>
    <h2 {...{
      "id": "constraints"
    }}>{`Constraints`}</h2>
    <p>{`But sometimes you need to annotate that your "type variable" can be only subtype of some existed in Hegel type. In Hegel you can annotate this super type after `}<inlineCode parentName="p">{`:`}</inlineCode>{` (colon).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function length<T: { length: number, ... }>(somethingWithLength: T) {
  return somethingWithLength.length; // 👌!
}

let result = 0;

result = length([1, 2, 3]);     // 👌!
result = length({ length: 4 }); // 👌!
result = length(() => 2);       // 👌!

// Error: Parameter "Set<number>" is incompatible with restriction "{ length: number, ... }"
// Because Set, WeakSet, Map and WeakMap has "size" property instead "length"
result = length(new Set<number>());
`}</code></pre>
    <p>{`Also, it works for primitive types`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function plus<T: number | bigint>(a: T, b: T): T {
  return a + b;
}

let result: bigint | number = 0;

result = plus(1, 2);   // 👌!
result = plus(1n, 2n); // 👌!

// Error: Parameter "'1'" is incompatible with restriction "bigint | number"
result = plus("1", "2");

// Error: Type "2n" is incompatible with type "number"
result = plus(1, 2n);
`}</code></pre>
    <h2 {...{
      "id": "default-type"
    }}>{`Default Type`}</h2>
    <p>{`You can also provide defaults for "type variable" the same as for a function argument.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Container<T = unknown> = { value: T };

// container type is "Container<unknown>"
const container: Container<> = { value: "something" };
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      